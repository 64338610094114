<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="lista_richieste_pretesseramento_hp"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :z-index="1100"
        ></loading>
        <div class="modal-header">
          <h5 class="modal-title">
            Richieste pretesseramento - {{ soggetto }} {{ cod_fisc }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <div class="mx-auto my-auto text-center" v-if="!loaded">
              <div class="spinner-border text-gray-600" role="status"></div>
              <span class="text-gray-600 ps-3 fs-2">Loading</span>
            </div>
            <div
              v-else-if="
                pretesseramento_richieste_list.length === 0 &&
                loaded &&
                (status == 200 || status == 204)
              "
              class="m-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
            >
              <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
              <span class="text-gray-700 ps-3 fs-2">
                Attenzione! Nessun risultato.
              </span>
            </div>
            <div
              v-else-if="status != 200 && status != 204"
              class="m-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
            >
              <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
              <span class="text-gray-700 ps-3 fs-2">
                Attenzione! Si è verificato un errore. Riprovare più tardi.
              </span>
              <span
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="text-gray-600 pt-4"
              >
                <router-link
                  v-if="isEnabled('fnLinkBachecaCompleta')"
                  to="/bacheca"
                >
                  <i class="bi bi-house fs-2 text-gray-800"> </i
                ></router-link>
                <router-link v-else to="/bacheca-public">
                  <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
              ></span>
            </div>
            <div v-else>
              <Datatable
                :table-header="tableHeader"
                :table-data="pretesseramento_richieste_list"
                :total="record"
                :current-page="currentPage"
                :rows-per-page="fetchRows"
                :sortLabel="sortColumn"
                :order="sortOrder"
                @sort="setSortOrderColumn"
                @items-per-page-change="setFetchRowsHPRichieste"
                @current-change="setCurrentPageHPRichieste"
                ><template v-slot:cell-stagione="{ row: data }">
                  {{ data.stagione }}
                </template>
                <template v-slot:cell-circolo="{ row: data }">
                  <div class="tab-long">
                    {{ data.circolo }}
                  </div>
                </template>
                <template v-slot:cell-cod_affiliazione="{ row: data }">
                  {{ data.cod_affiliazione }}
                </template>
                <template v-slot:cell-tipo_tessera="{ row: data }">
                  {{ data.tipo_tessera }}
                </template>
                <template v-slot:cell-data_richiesta="{ row: data }">
                  {{ data.data_richiesta }}
                </template>
                <template v-slot:cell-stato="{ row: data }">
                  <h1
                    class="badge"
                    :class="
                      data.stato == 'Nuova'
                        ? 'badge-light-primary'
                        : data.stato == 'Accettata'
                        ? 'badge-light-success'
                        : data.stato == 'Rifiutata'
                        ? 'badge-light-danger'
                        : 'badge-light-warning'
                    "
                  >
                    {{ data.stato }}
                  </h1>
                </template>
                <template v-slot:cell-annullata="{ row: data }">
                  <span v-if="data.annullata">Si</span><span v-else>No</span>
                </template>
                <template v-slot:cell-options="{ row: data }">
                  <div
                    class="btn-group"
                    v-if="
                      (data.stato == 'Nuova' || data.stato == 'In redazione') &&
                      !data.annullata
                    "
                  >
                    <button
                      v-if="!readOnly"
                      class="badge badge-light-danger"
                      @click="eliminaRichiestaHP(data.id)"
                    >
                      Annulla richiesta
                    </button>
                  </div></template
                >
              </Datatable>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { ref, computed, getCurrentInstance } from "vue";

import { alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { delRichiesteHp } from "@/requests/PretesseramentoRequests";

export default {
  name: "lista-richieste-pretesseramento-hp",
  emits: ["refreshList"],
  props: {
    soggetto: {
      type: String,
      required: true,
    },
    cod_fisc: {
      type: String,
      required: true,
    },
  },
  components: {
    Datatable,
    Loading,
  },
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsHPRichieste = (e) => {
      store.commit("setFetchRowsHPRichieste", e);
      emit("refreshList", props.cod_fisc);
    };
    const setCurrentPageHPRichieste = (page) => {
      store.commit("setCurrentPageHPRichieste", page);
      emit("refreshList", props.cod_fisc);
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnHPRichieste", columnName);
      store.commit("setSortOrderHPRichieste", order);
      emit("refreshList", props.cod_fisc);
    };

    const isLoading = ref(false);

    const eliminaRichiestaHP = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "La richiesta verrà annullata definitivamente",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Indietro",
        confirmButtonText: "Annulla richiesta",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await delRichiesteHp(id, props.cod_fisc).then((res) => {
            if (res.status == 200) {
              alertSuccess("Richiesta annullata correttamente");
              emit("refreshList", props.cod_fisc);
            } else {
              const strMsg = ref(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            isLoading.value = false;
          });
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
      },
      {
        name: "Circolo",
        key: "circolo",
      },
      {
        name: "Cod. Aff.",
        key: "cod_affiliazione",
      },
      {
        name: "Tipo Tessera",
        key: "tipo_tessera",
      },
      {
        name: "Data Richiesta",
        key: "data_richiesta",
      },
      {
        name: "Stato",
        key: "stato",
      },
      {
        name: "Annullata",
        key: "annullata",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    return {
      tableHeader,
      isEnabled,
      pretesseramento_richieste_list: computed(() =>
        store.getters.getStateFromName("resultspretesseramento_richieste_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedpretesseramento_richieste_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordpretesseramento_richieste_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuspretesseramento_richieste_list")
      ),
      currentPage: computed(() => store.getters.currentPageHPRichieste),
      rowsToSkip: computed(() => store.getters.rowsToSkipHPRichieste),
      fetchRows: computed(() => store.getters.fetchRowsHPRichieste),
      sortColumn: computed(() => store.getters.sortColumnHPRichieste),
      sortOrder: computed(() => store.getters.sortOrderHPRichieste),
      setFetchRowsHPRichieste,
      setCurrentPageHPRichieste,
      setSortOrderColumn,
      isLoading,
      eliminaRichiestaHP,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  max-width: 50%;
  margin-top: 3rem;
  margin-left: 25rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
