<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        pretesseramento_anagrafiche_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="pretesseramento_anagrafiche_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsHPAnagrafiche"
        @current-change="setCurrentPageHPAnagrafiche"
      >
        <template v-slot:cell-cod_fisc="{ row: data }">
          {{ data.cod_fisc }}
        </template>
        <template v-slot:cell-soggetto="{ row: data }"
          ><div class="tab-long">
            {{ data.soggetto }}
          </div></template
        >
        <template v-slot:cell-email="{ row: data }">
          {{ data.email }}
        </template>
        <template v-slot:cell-data_nascita="{ row: data }">
          {{ data.data_nascita }}
        </template>
        <template v-slot:cell-codice_controllo="{ row: data }">
          {{ data.codice_controllo }}
        </template>
        <template v-slot:cell-options="{ row: data }"
          >{{ data.options }}
          <div class="btn-group">
            <button
              class="navi-link"
              data-bs-toggle="modal"
              data-bs-target="#lista_richieste_pretesseramento_hp"
              @click="
                getHpRichieste(data.cod_fisc);
                selectedPersona = data.soggetto;
                selectedCodFisc = data.cod_fisc;
              "
            >
              <i class="bi bi-eye text-dark fs-5"></i>
            </button>
            <button
              v-if="!readOnly"
              class="navi-link"
              @click="eliminaAnagraficaHP(data.cod_fisc, data.soggetto)"
            >
              <i class="bi bi-trash text-danger fs-5"></i>
            </button></div
        ></template>
      </Datatable>
    </div>
    <ListaRichiestePretesseramento
      :soggetto="selectedPersona"
      :cod_fisc="selectedCodFisc"
      @refreshList="getHpRichieste"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { computed, getCurrentInstance, ref } from "vue";
import ListaRichiestePretesseramento from "@/components/components-fit/helpdesk-pretesseramento/ListaRichiestePretesseramentoHP.vue";

import { alertSuccess } from "@/composables/swAlert";
import isEnabled from "@/composables/isEnabled.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { delAnagraficaHp } from "@/requests/PretesseramentoRequests";

export default {
  name: "table-hp-pretesseramento",
  components: { Datatable, ListaRichiestePretesseramento, Loading },
  emits: ["refreshList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const selectedPersona = ref(null);

    const setFetchRowsHPAnagrafiche = (e) => {
      store.commit("setFetchRowsHPAnagrafiche", e);
      emit("refreshList");
    };
    const setCurrentPageHPAnagrafiche = (page) => {
      store.commit("setCurrentPageHPAnagrafiche", page);
      emit("refreshList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnHPAnagrafiche", columnName);
      store.commit("setSortOrderHPAnagrafiche", order);
      emit("refreshList");
    };

    const isLoading = ref(false);

    const eliminaAnagraficaHP = (cod_fisc, soggetto) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text:
          "Procedendo verranno cancellati i dati anagrafici e tutte le richieste di " +
          soggetto,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await delAnagraficaHp(cod_fisc).then((res) => {
            if (res.status == 200) {
              alertSuccess("Anagrafica eliminata correttamente");
              emit("refreshList");
            } else {
              const strMsg = ref(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            isLoading.value = false;
          });
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Codice fiscale",
        key: "cod_fisc",
      },
      {
        name: "Soggetto",
        key: "soggetto",
      },
      {
        name: "E-mail",
        key: "email",
      },
      {
        name: "Data di nascita",
        key: "data_nascita",
      },
      {
        name: "Codice controllo",
        key: "codice_controllo",
      },
      { key: "options", sortable: false },
    ]);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkipRichieste = computed(
      () => store.getters.rowsToSkipHPRichieste
    );
    const fetchRowsRichieste = computed(
      () => store.getters.fetchRowsHPRichieste
    );
    const sortColumnRichieste = computed(
      () => store.getters.sortColumnHPRichieste
    );
    const sortOrderRichieste = computed(
      () => store.getters.sortOrderHPRichieste
    );

    const selectedCodFisc = ref(null);

    const getHpRichieste = (selectedCodFisc) => {
      store.dispatch("setStoreListData", {
        keys: {
          cod_fisc: selectedCodFisc,
          rowstoskip: rowsToSkipRichieste.value,
          fetchrows: fetchRowsRichieste.value,
          sortcolumn: sortColumnRichieste.value,
          sortorder: sortOrderRichieste.value,
        },
        apiLink: globalApi.PRETESSERAMENTO_RICHIESTE_LIST,
        itemName: "pretesseramento_richieste_list",
      });
    };

    return {
      tableHeader,
      isEnabled,
      pretesseramento_anagrafiche_list: computed(() =>
        store.getters.getStateFromName(
          "resultspretesseramento_anagrafiche_list"
        )
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedpretesseramento_anagrafiche_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordpretesseramento_anagrafiche_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuspretesseramento_anagrafiche_list")
      ),
      currentPage: computed(() => store.getters.currentPageHPAnagrafiche),
      rowsToSkip: computed(() => store.getters.rowsToSkipHPAnagrafiche),
      fetchRows: computed(() => store.getters.fetchRowsHPAnagrafiche),
      sortColumn: computed(() => store.getters.sortColumnHPAnagrafiche),
      sortOrder: computed(() => store.getters.sortOrderHPAnagrafiche),
      setFetchRowsHPAnagrafiche,
      setCurrentPageHPAnagrafiche,
      setSortOrderColumn,
      selectedPersona,
      isLoading,
      eliminaAnagraficaHP,

      rowsToSkipRichieste,
      fetchRowsRichieste,
      sortColumnRichieste,
      sortOrderRichieste,
      selectedCodFisc,
      getHpRichieste,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped></style>
