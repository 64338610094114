<template>
  <div>
    <FilterHpAnagrafiche
      @refreshList="searchHpAnagrafiche"
      @resetFilters="resetFilters"
    />
    <TableHpAnagrafiche
      @refreshList="getHpAnagrafiche"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FilterHpAnagrafiche from "@/components/components-fit/helpdesk-pretesseramento/FilterHpAnagrafiche.vue";
import TableHpAnagrafiche from "@/components/components-fit/helpdesk-pretesseramento/TableHpAnagrafiche.vue";

export default defineComponent({
  name: "helpdesk-pretesseramento",
  components: {
    FilterHpAnagrafiche,
    TableHpAnagrafiche,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Helpdesk Pretesseramento", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipHPAnagrafiche);
    const fetchRows = computed(() => store.getters.fetchRowsHPAnagrafiche);
    const sortColumn = computed(() => store.getters.sortColumnHPAnagrafiche);
    const sortOrder = computed(() => store.getters.sortOrderHPAnagrafiche);

    const nome = computed(() => store.getters.nomeHPAnagrafiche);
    const cognome = computed(() => store.getters.cognomeHPAnagrafiche);
    const cod_fisc = computed(() => store.getters.codFiscHPAnagrafiche);
    const email = computed(() => store.getters.emailHPAnagrafiche);

    const getHpAnagrafiche = () => {
      store.dispatch("setStoreListData", {
        keys: {
          nome: trimInput(nome.value),
          cognome: trimInput(cognome.value),
          cod_fisc: trimInput(cod_fisc.value),
          email: trimInput(email.value),
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.PRETESSERAMENTO_ANAGRAFICHE_LIST,
        itemName: "pretesseramento_anagrafiche_list",
      });
    };
    getHpAnagrafiche();

    const resetFilters = () => {
      store.commit("resetFiltersHPAnagrafiche");
      getHpAnagrafiche();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedpretesseramento_anagrafiche_list")
    );

    const searchHpAnagrafiche = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipHPAnagrafiche");
      getHpAnagrafiche();
    };

    return {
      getHpAnagrafiche,
      resetFilters,
      searchHpAnagrafiche,
    };
  },
});
</script>
